import React from 'react';

class App extends React.Component {
    render() {
        return <div id='app-container'>
                    <h1 className='code-text center'>We are </h1>
                    <h1 className='code-text center'>1x developers</h1>
                </div>;
    }
}

export default App;
